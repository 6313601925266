import request from '@/utils/request'

const skillApi = {
  list: '/goods/skills',
  create: '/goods/skills',
  update: id => `/goods/skills/${id}`,
  detail: id => `/goods/skills/${id}`,
  remove: id => `/goods/skills/${id}`,
  switchStatus: id => `/goods/skills/status/${id}`
}

export function list(parameter) {
  return request({
    url: skillApi.list,
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: skillApi.create,
    method: 'post',
    data: parameter
  })
}

export function update(parameter) {
  const { goodsId } = parameter
  delete parameter.goodsId
  return request({
    url: skillApi.update(goodsId),
    method: 'put',
    data: parameter
  })
}

export function detail(parameter) {
  return request({
    url: skillApi.detail(parameter),
    method: 'get'
  })
}

export function remove(parameter) {
  return request({
    url: skillApi.remove(parameter),
    method: 'delete'
  })
}

export function switchStatus(parameter) {
  return request({
    url: skillApi.switchStatus(parameter),
    method: 'patch'
  })
}
