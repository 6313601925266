import request from '@/utils/request'

const skillApi = {
  list: '/skills',
  baseSkillList: '/skills/base',
  baseSkillSort: '/skills/base/sorts',
  create: '/skills',
  update: id => `/skills/${id}`,
  detail: id => `/skills/${id}`,
  remove: id => `/skills/${id}`,
  sort: id => `/skills/sort/${id}`,
  switchStatus: id => `/skills/status/${id}`,
  createPhase: id => `/skills/${id}/phase`,
  updatePhase: ({ skillId, id }) => `/skills/${skillId}/phase/${id}`,
  phaseDetail: ({ skillId, id }) => `/skills/${skillId}/phase/${id}`,
  removePhase: ({ skillId, id }) => `/skills/${skillId}/phase/${id}`
}

export function list(parameter) {
  return request({
    url: skillApi.list,
    method: 'get',
    params: parameter
  })
}

export function baseSkillList() {
  return request({
    url: skillApi.baseSkillList,
    method: 'get'
  })
}

export function baseSkillSort(parameter) {
  return request({
    url: skillApi.baseSkillSort,
    method: 'put',
    data: parameter
  })
}

export function create(parameter) {
  return request({
    url: skillApi.create,
    method: 'post',
    data: parameter
  })
}

export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: skillApi.update(id),
    method: 'put',
    data: parameter
  })
}

export function detail(parameter) {
  return request({
    url: skillApi.detail(parameter),
    method: 'get'
  })
}

export function remove(parameter) {
  return request({
    url: skillApi.remove(parameter),
    method: 'delete'
  })
}

export function sort(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: skillApi.sort(id),
    method: 'put',
    data: parameter
  })
}

export function switchStatus(parameter) {
  return request({
    url: skillApi.switchStatus(parameter),
    method: 'patch'
  })
}

export function createPhase(parameter) {
  const { skillId } = parameter
  delete parameter.skillId
  return request({
    url: skillApi.createPhase(skillId),
    method: 'post',
    data: parameter
  })
}

export function updatePhase(parameter) {
  const { skillId, id } = parameter
  delete parameter.skillId
  delete parameter.id
  return request({
    url: skillApi.updatePhase({ skillId, id }),
    method: 'put',
    data: parameter
  })
}

export function phaseDetail(parameter) {
  const { skillId, id } = parameter
  delete parameter.skillId
  delete parameter.id
  return request({
    url: skillApi.phaseDetail({ skillId, id }),
    method: 'get'
  })
}

export function removePhase(parameter) {
  const { skillId, id } = parameter
  delete parameter.skillId
  delete parameter.id
  return request({
    url: skillApi.removePhase({ skillId, id }),
    method: 'delete'
  })
}
