<template>
  <a-card>
    <div slot="title">基本信息</div>
    <a-form-model v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="封面：">
        <a-avatar :size="70" shape="square" :src="goodsSource.coverUrl"></a-avatar>
      </a-form-model-item>
      <a-form-model-item label="名称：">
        <span>{{ goodsSource.goodsName }}</span>
      </a-form-model-item>
      <a-form-model-item label="分类：">
        <span>{{ goodsSource.classification }}</span>
      </a-form-model-item>
      <a-form-model-item label="原始售价：">
        <span>{{ goodsSource.originPrice }}</span>
      </a-form-model-item>
      <!--      不显示折扣，暂时注释-->
      <!--      <a-form-model-item label="折扣：">-->
      <!--        <span>{{ goodsSource.discount }}</span>-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="实际售价：">
        <span>{{ goodsSource.sellPrice }}</span>
      </a-form-model-item>
    </a-form-model>
    <a-row>
      <a-col :sm="18" :xs="{ span: 22, offset: 1 }">
        <a-card>
          <a-row type="flex" align="middle">
            <a-col style="margin-right:20px">
              <a-avatar :size="70" shape="square" :src="skillSource.coverUrl"></a-avatar>
            </a-col>
            <a-col flex="1" class="skill-detail">
              <a-row style="font-size:14px">{{ skillSource.name }}</a-row>
              <a-row style="font-size:12px" type="flex" align="middle" :gutter="12">
                <a-col>分类：{{ skillSource.field.parent.name }} > {{ skillSource.field.name }}</a-col>
                <a-col>阶段数：{{ skillSource.phaseCount }} </a-col>
                <a-col>创建人：{{ skillSource.creator }}</a-col>
              </a-row>
              <a-row style="font-size:12px">目标：{{ skillSource.goal }}</a-row>
            </a-col>
          </a-row>
        </a-card>
        <a-card :bordered="false">
          <a-col
            type="flex"
            align="middle"
            v-for="(phaseInfo, phaseIndex) in skillSource.phaseList"
            :key="phaseInfo.id"
            style="margin-bottom:18px"
          >
            <a-row type="flex" align="middle" style="font-size:16px">
              <a-icon style="margin-right:6px" type="tag" theme="filled" :rotate="135" />
              <a-col>第{{ phaseIndex + 1 }}阶段</a-col>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top:8px;"
              ><a-col style="font-size:12px;padding-left:20px">阶段目标：</a-col
              ><a-col style="font-size:14px">{{ phaseInfo.goal }}</a-col></a-row
            >
          </a-col>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import * as goodsskillApi from '@/api/goodsskill'
import * as skillApi from '@/api/skill'
export default {
  name: 'GoodsManagementSkillDetail',
  data() {
    return {
      skillSource: {
        coverUrl: '',
        name: '',
        field: {
          parent: {}
        },
        creator: '',
        phaseCount: '',
        goal: '',
        phaseList: []
      },
      goodsSource: {
        coverUrl: '',
        classification: '',
        goodsName: '',
        discount: '',
        originPrice: '',
        sellPrice: ''
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      goodsskillApi.detail(id).then(res => {
        const {
          skillId,
          classification: { name },
          coverUrl,
          goodsName,
          discount,
          originPrice,
          sellPrice
        } = res.data
        this.goodsSource = { classification: name, coverUrl, goodsName, discount, originPrice, sellPrice }

        if (skillId) {
          skillApi.detail(skillId).then(res => {
            const { name, coverUrl, field, creator, phaseCount, goal, phaseList } = res.data
            this.skillSource = { name, coverUrl, field, creator, phaseCount, goal, phaseList }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.skill-detail {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
